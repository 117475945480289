import React from 'react'

const LanguageOptions = () => {
    return (
        <>
            <option value="bo">བོད</option>
            <option value="en">English</option>,
        </>
    )
}

export default LanguageOptions
